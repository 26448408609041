import React, { useState } from 'react';
import { Card, CardMedia, CardContent, IconButton, Typography, Box, Snackbar } from '@mui/material';
import { Settings, Delete } from '@mui/icons-material';
import { useDrag, useDrop } from 'react-dnd';
import { capitalizerFirstLetter } from '../../utils';

export const VideoCard = ({ video, index, moveVideo, openVideoSettings, isGridView, handleDeleteVideo }) => {
    const [{ isDraggingCurrent }, ref] = useDrag({
        type: 'imagem',
        item: () => ({ index }),
        collect: (monitor) => ({
            isDraggingCurrent: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'imagem',
        hover: (item) => {
            if (item.index !== index) {
                moveVideo(item.index, index);
                item.index = index;
            }
        },
    });

    return (
        <Card
            sx={{
                width: isGridView ? '253px' : 'auto',
                maxWidth: isGridView ? 'none' : '80%',
                display: 'flex',
                flexDirection: isGridView ? 'column' : 'row',
                position: 'relative',
                marginBottom: '8px',
                boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
                opacity: isDraggingCurrent ? 0.5 : 1,
                borderRadius: '8px',
                transition: 'border 0.2s ease, box-shadow 0.2s ease',
                border: isDraggingCurrent ? '2px dashed var(--primary-color)' : 'none',
                overflow: 'hidden',
                cursor: 'pointer',
                padding: '18px',
            }}
            ref={(node) => ref(drop(node))}
            key={index}
        >
            <CardMedia
                component="img"
                image={video.capa}
                alt={video.titulo}
                sx={{
                    width: isGridView ? '100%' : '70px',
                    height: isGridView ? '450px' : 'auto',
                    aspectRatio: '16/9',
                    borderRadius: '8px',
                }}
            />

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: isGridView ? 'flex-start' : 'center',
                    flex: 1,
                    p: 2,
                    m: 0,
                    width: '100%',
                }}
            >
                <Typography variant="body2" color="textSecondary" sx={{ flex: 1 }}>
                    {capitalizerFirstLetter(video.titulo ?? 'Sem título')}
                </Typography>
                <IconButton onClick={() => handleDeleteVideo(video.idVideo)}>
                    <Delete />
                </IconButton>
                <IconButton onClick={openVideoSettings}>
                    <Settings />
                </IconButton>
            </CardContent>
        </Card>
    );
};
