import { Delete } from '../http/httpVerbs';

export const apiDeleteFeedVideo = async (idFeed, idVideo) => {
    try {
        const response = await Delete(`${process.env.REACT_APP_API_URL}/feeds/${idFeed}/video/${idVideo}`);

        return response;
    } catch (error) {
        throw error;
    }
};
